import React, { useState, useEffect } from 'react';
import styles from './Transactiondetails.module.scss';
import client from '../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import ChargebackIcon from '../../../assets/images/Icons/chargeback-white.svg';
import StatusView from '../../../components/StatusView/StatusView';
import Modal from 'react-modal';
import Single from '../../Chargeback/Single';
import { customStyles } from '../../../constants/modalStyles';
import { numberWithCommas } from '../../../utils/formatNumber';

interface IDtypes {
	id: number | null;
	setId: React.Dispatch<React.SetStateAction<number | null>>;
}

const TransactionDetails = ({ id, setId }: IDtypes) => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const [modalIsOpenSingle, setIsOpenSingle] = React.useState(false);
	const [bearer, setBearer] = useState(false);

	function closeModalSingle() {
		setIsOpenSingle(false);
	}
	useEffect(() => {
		if (id) {
			dispatch(openLoader());
			setIsLoading(true);
			client
				.get(`order/all/paginated?id=${id}`)
				.then((res: any) => {
					setCustomerDetails(res?.data?.data?.items[0]);
					setIsLoading(false);
					dispatch(closeLoader());
				})
				.catch((error) => {
					dispatch(closeLoader());
					setIsLoading(false);
					if (error.response) {
						const message = error?.response?.data?.message;
						dispatch(
							openToastAndSetContent({
								toastStyles: ToastErrorStyles,
								toastContent: message,
							})
						);
					} else if (error.request) {
						console.log(error.request);
					} else {
						console.log('Error', error.message);
					}
				});
		}
	}, [id]);

	const payload = {
		transactionReference: customerDetails?.paymentReference,
		amount: customerDetails?.amount - customerDetails?.fee,
	};

	const handleChargebacks = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader())
		client
			.post('/refund/transaction', [payload])
			.then((data: any) => {
				const message = data?.data?.message;
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastSuccessStyles,
						toastContent: message,
					})
				);
			})
			.catch((error: any) => {
				if (error.response) {
					const message = error?.response?.data?.message;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			}).finally(() => {
				dispatch(closeLoader())
			});
	};

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionInfo}>
						<div className={styles.transactionsHeader}>
							<div
								style={{
									color: '#616161',
									display: 'inline',
									cursor: 'pointer',
								}}
								onClick={() => setId(null)}>
								Transactions
							</div>
							{' >'} Transaction details
						</div>
					</div>
					<div className={styles.topTable}>
						<div className={styles.detailsHeader}>Transaction details</div>
						<div className={styles.tableContent}>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction date</div>
								<div className={styles.detailsKey}>
									{moment(customerDetails?.dateCreated).format(
										'MMMM Do YYYY, h:mm a'
									)}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Status</div>
								<div className={styles.detailsKey}>
									<StatusView
										status={customerDetails?.orderStatus}
										green='Successful'
										blue='Initiated'
										orange='Pending'
										red='Failed'
										purple='Reversed'
									/>
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment Message</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentResponseMessage || 'Not provided'}

								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment channel</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentTypeName || customerDetails?.paymentType || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer name</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerName}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer email</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerEmail}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer phone</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerPhone}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant ID</div>
								<div className={styles.detailsKey}>
									{customerDetails?.subsidiaryId}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Account number</div>
								<div className={styles.detailsKey}>
									{customerDetails?.accountNumber || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Card Type</div>
								<div className={styles.detailsKey}>
									{customerDetails?.cardType || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Masked PAN</div>
								<div className={styles.detailsKey}>
									{customerDetails?.maskedPan || 'Not provided'}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>IP Addresss</div>
								<div className={styles.detailsKey}>
									{customerDetails?.ipAddress || "N/A"}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Transaction amount</div>
								<div className={styles.detailsKey}>
									{customerDetails?.currency}{customerDetails?.amount}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.paymentReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>RRN/SessionId</div>
								<div className={styles.detailsKey}>
									{customerDetails?.externalReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Provider Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.internalProviderReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Fee</div>
								<div className={styles.detailsKey}>
									{customerDetails?.fee}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Who bore fee</div>
								<div className={styles.detailsKey}>
									{customerDetails?.whoBearsFee}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Customer Fee / Subsidiary Fee</div>
								<div className={styles.detailsKey}>
									{customerDetails?.customerFee} / {customerDetails?.subsidiaryFee}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Settlement Amount</div>
								<div className={styles.detailsKey}>
									{numberWithCommas(customerDetails?.settlementAmount)} {`(${customerDetails?.settlementStatus || 'N/A'})`}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Initial Settlement Date</div>
								<div className={styles.detailsKey}>
									{customerDetails?.initialSettlementDate || "Not Available"}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Final Settlement Date</div>
								<div className={styles.detailsKey}>
									{customerDetails?.finalSettlementDate || "Not Available"}
								</div>
							</div>
							{/* <div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Device fingerprint</div>
								<div className={styles.detailsKey}>XXXXXXXXXXXX</div>
							</div> */}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Merchant name</div>
								<div className={styles.detailsKey}>
									{customerDetails?.subsidiaryName}
								</div>
							</div>{' '}
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Order reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.orderReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Note</div>
								<div className={styles.detailsKey}>
									{customerDetails?.remarks}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Payment Attempt Reference</div>
								<div className={styles.detailsKey}>
									{customerDetails?.orderPaymentReference}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Bank Provider</div>
								<div className={styles.detailsKey}>
									{customerDetails?.processorProviderBank || "Not Available"}
								</div>
							</div>
							<div className={styles.customerInfo}>
								<div className={styles.detailsValue}>Bank Provider Username</div>
								<div className={styles.detailsKey}>
									{customerDetails?.processorProviderUsername || "Not Available"}
								</div>
							</div>
						</div>
						<div className={styles.chargeBack}>
							<button
								className={styles.chargeBackbutton}
								onClick={handleChargebacks}>
								<div>
									<img src={ChargebackIcon} alt='chargeback' />
								</div>
								<div className={styles.ml}>Initiate Refund</div>
							</button>
							<button
								className={styles.chargeBackbutton}
								onClick={() => setIsOpenSingle(true)}>
								<div>
									<img src={ChargebackIcon} alt='chargeback' />
								</div>
								<div className={styles.ml}>Initiate Chargeback</div>
							</button>
						</div>
					</div>
					<Modal
						isOpen={modalIsOpenSingle}
						onRequestClose={closeModalSingle}
						contentLabel='Example Modal'
						style={customStyles}>
						<Single orderDetails={customerDetails} setBearer={setBearer} closeModal={closeModalSingle} />
					</Modal>
				</>
			)}
		</div>
	);
};

export default TransactionDetails;
